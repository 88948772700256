// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-assessment-framework-jsx": () => import("./../../../src/pages/assessment-framework.jsx" /* webpackChunkName: "component---src-pages-assessment-framework-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-esg-dashboard-jsx": () => import("./../../../src/pages/esg-dashboard.jsx" /* webpackChunkName: "component---src-pages-esg-dashboard-jsx" */),
  "component---src-pages-how-it-works-jsx": () => import("./../../../src/pages/how-it-works.jsx" /* webpackChunkName: "component---src-pages-how-it-works-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insights-jsx": () => import("./../../../src/pages/insights.jsx" /* webpackChunkName: "component---src-pages-insights-jsx" */),
  "component---src-pages-meet-the-team-jsx": () => import("./../../../src/pages/meet-the-team.jsx" /* webpackChunkName: "component---src-pages-meet-the-team-jsx" */),
  "component---src-pages-metrics-jsx": () => import("./../../../src/pages/metrics.jsx" /* webpackChunkName: "component---src-pages-metrics-jsx" */),
  "component---src-pages-our-story-jsx": () => import("./../../../src/pages/our-story.jsx" /* webpackChunkName: "component---src-pages-our-story-jsx" */),
  "component---src-pages-policies-jsx": () => import("./../../../src/pages/policies.jsx" /* webpackChunkName: "component---src-pages-policies-jsx" */),
  "component---src-pages-private-equity-jsx": () => import("./../../../src/pages/private-equity.jsx" /* webpackChunkName: "component---src-pages-private-equity-jsx" */),
  "component---src-pages-sparta-capital-jsx": () => import("./../../../src/pages/sparta-capital.jsx" /* webpackChunkName: "component---src-pages-sparta-capital-jsx" */),
  "component---src-pages-tutorial-video-hub-jsx": () => import("./../../../src/pages/tutorial-video-hub.jsx" /* webpackChunkName: "component---src-pages-tutorial-video-hub-jsx" */),
  "component---src-templates-insights-content-page-template-jsx": () => import("./../../../src/templates/InsightsContentPageTemplate.jsx" /* webpackChunkName: "component---src-templates-insights-content-page-template-jsx" */),
  "component---src-templates-job-posting-page-template-jsx": () => import("./../../../src/templates/JobPostingPageTemplate.jsx" /* webpackChunkName: "component---src-templates-job-posting-page-template-jsx" */),
  "component---src-templates-tutorial-video-page-template-jsx": () => import("./../../../src/templates/TutorialVideoPageTemplate.jsx" /* webpackChunkName: "component---src-templates-tutorial-video-page-template-jsx" */),
  "component---src-templates-video-page-template-jsx": () => import("./../../../src/templates/VideoPageTemplate.jsx" /* webpackChunkName: "component---src-templates-video-page-template-jsx" */)
}

